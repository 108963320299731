/* @import url("./font.css"); */
/* * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "AvenirNext";
}
body {
  padding: 0;
  margin: 0;
  width: 100%;
  float: left;
  height: 100%;
  overflow-x: hidden;
  background: #fcfcfc;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "GT Walsheim Pro";
  margin-bottom: 0;
}
a {
  text-decoration: none;
}
ul {
  margin-bottom: 0;
}
ul,
li {
  list-style: none;
}
img {
  width: 100%;
}
p {
  font-size: 15px;
  line-height: 20px;
  width: 100%;
  float: left;
  font-weight: 200;
  margin-bottom: 0;
}
::selection {
  background: var(--blue-color);
  color: var(--white-color);
} */
.full-size {
  width: 100%;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
}
.box-shadow {
  -webkit-box-shadow: 0 .15rem 1.75rem 0 rgba(58, 59, 69, .15);
    box-shadow: 0 .15rem 1.75rem 0 rgba(58, 59, 69, .15);
}
.box-shadow1 {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
.box-shadow2 {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
.box-shadow3 {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}
a:hover {
  text-decoration: none;
}
:root {
  --blue-color: #2aa8f2;
  --black-color: #252525;
  --white-color: #ffffff;
  --heading-color: #272d37;
  --pera-color: #36414c;
  --gray-color: #8f9ca9;
}

/*=========================scroll bar sec css start=============================*/
/* body::-webkit-scrollbar {
  width: 7px;
}
body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
body::-webkit-scrollbar-thumb {
  background: linear-gradient(0deg, #e54e94 0%, #00cdff 100%);
  outline: 1px solid slategrey;
} */
/*=========================heading css start=============================*/
.heading {
  width: 100%;
  float: left;
  position: relative;
}
.heading h4 {
  font-size: 20px;
  font-weight: 500;
  color: var(--heading-color);
  text-transform: capitalize;
  margin-top: 15px;
}
/*=========================button css start=============================*/
.button a {
  display: inline-block;
  width: 160px;
  height: 40px;
  background: var(--blue-color);
  text-transform: capitalize;
  line-height: 40px;
  border-radius: 2px;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  color: var(--white-color);
}
.button a i {
  font-size: 20px;
  position: relative;
  top: 2px;
}
/*=========================custom form css start=============================*/
.form-control:focus,
.custom-select:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff !important;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%) !important;
}
.custom-select {
  /* background-image: url("../images/icon/down-arrow.svg"); */
  background-repeat: no-repeat;
  background-size: 10px;
  background-position: right 0.75rem center;
  border-radius: 0;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 4px;
}
.custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}
.custom-control-input {
  position: inherit;
}
.custom-control-label::before {
  border-radius: 4px;
}
.input-feild {
  width: 100%;
  float: left;
  margin-top: 10px;
}
.input-feild input,
.input-feild textarea {
  width: 100%;
  float: left;
  height: 43px;
  padding: 0 12px;
  color: #272d37;
  font-size: 14px;
  font-weight: 500;
  outline: none;
  border-radius: 0;
  background: var(--white);
  border: none;
  border: 1px solid #dcdcdc;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 4px;
}
.input-feild .name {
  width: 100%;
  float: left;
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 5px;
  color: #66737f;
  text-transform: capitalize;
}
.input-button {
  width: 100%;
  float: left;
  margin-top: 25px;
  padding: 0 15px;
}
.input-button input {
  width: 140px;
  height: 40px;
  border-radius: 3px;
  font-size: 15px;
  font-weight: 500;
  color: var(--white-color);
  border: none;
  background: var(--blue-color);
  box-shadow: rgb(0 0 0 / 10%) 0px 4px 12px;
}
.radio {
  position: relative;
  font-size: 16px;
  font-weight: 400;
  text-transform: capitalize;
  display: inline-block;
  vertical-align: middle;
  color: var(--secondary-color);
  padding-left: 20px;
  margin-top: 10px;
  line-height: 14px;
}
.radio input,
.radio1 input[type="radio"] {
  opacity: 0;
  position: absolute;
  width: 0;
  height: 0;
}
.radio small {
  font-size: 14px;
  font-family: "AvenirNext";
  font-weight: 500;
  color: #8f9ca9;
  text-transform: capitalize;
}
.radio span {
  height: 14px;
  width: 14px;
  border-radius: 50%;
  border: 1px solid #8f9ca9;
  display: block;
  position: absolute;
  left: 0;
  top: 7px;
  margin-top: -6px;
}
.radio span::before {
  position: absolute;
  content: "";
  height: 6px;
  width: 6px;
  background: var(--blue);
  display: block;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale(0);
  border-radius: 50%;
  transition: 300ms ease-in-out 0s;
}
.radio input[type="radio"]:checked ~ span::before {
  transform: translate(-50%, -50%) scale(1);
}
.radio input[type="radio"]:checked ~ small {
  color: var(--heading-color);
}
/*=========================sidebar sec css start=============================*/
.left-sidebar::-webkit-scrollbar {
  width: 6px;
}
.left-sidebar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.left-sidebar::-webkit-scrollbar-thumb {
  background: linear-gradient(0deg, #e54e94 0%, #00cdff 100%);
  outline: 1px solid slategrey;
}
.left-sidebar {
  background: var(--black-color);
  position: fixed;
  width: 270px;
  height: 100%;
  padding: 25px 0;
  overflow: auto;
  transition: all 0.5s ease-in-out;
}
/* .left-sidebar.show {
  display: none;
} */
.toggler,
.close-toggler {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 15px 20px;
  margin-bottom: 15px;
}
.toggler .bar span {
  width: 30px;
  height: 3px;
  display: block;
  border-radius: 35px;
  background: var(--white);
  margin-bottom: 6px;
  transition: 0.5s ease-in-out;
  position: relative;
}
.toggler .bar span:nth-child(2) {
  width: 33px;
}
.toggler .bar span:last-child {
  margin-bottom: 0;
  width: 23px;
}
.toggler .bar {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.toggler .bar:hover span {
  width: 33px;
}
.toggler .bar.active span:nth-child(2) {
  opacity: 0;
  visibility: hidden;
}
.toggler .bar.active span:first-child {
  width: 33px;
  transform: rotate(-45deg);
  top: 12px;
}
.toggler .bar.active span:last-child {
  width: 33px;
  transform: rotate(45deg);
  top: -6px;
}
.close-toggler {
  display: none;
}
.close-toggler .bar {
  cursor: pointer;
}
.close-toggler span {
  width: 35px;
  height: 3px;
  display: block;
  border-radius: 35px;
  background: var(--white);
  margin-bottom: 6px;
  transition: 0.5s ease-in-out;
  position: relative;
}
.close-toggler span:nth-child(2) {
  opacity: 0;
  visibility: hidden;
}
.close-toggler span:first-child {
  width: 35px;
  transform: rotate(-45deg);
  top: 12px;
}
.close-toggler span:last-child {
  width: 35px;
  transform: rotate(45deg);
  top: -6px;
}
.left-sidebar .profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 10px 0;
}
.left-sidebar .profile .image {
  width: 112px;
  height: 112px;
  border-radius: 50%;
}
.left-sidebar .detail {
  margin-top: 10px;
  text-align: center;
}
.left-sidebar .detail h4 {
  font-size: 15px;
  font-weight: 400;
  color: var(--white);
  text-transform: capitalize;
}
.left-sidebar .detail p {
  font-size: 14px;
  margin-bottom: 0;
  margin-top: 5px;
  color: #c2c2c2;
  text-transform: capitalize;
  font-weight: 500;
}
.left-sidebar nav {
  width: 100%;
  float: left;
  margin-top: 25px;
}
.left-sidebar nav h5 {
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  color: #a0a0a0;
  padding: 0 20px;
  margin-bottom: 10px;
}
.left-sidebar nav ul {
  padding: 0;
  width: 100%;
}
.left-sidebar nav ul li {
  display: block;
}
.left-sidebar nav ul li a {
  width: 100%;
  padding: 1rem;
  text-transform: capitalize;
  font-size: 1rem;
  font-weight: 500;
  position: relative;
  color: #8f9ca9;
  transition: border 0.5s ease-in-out;
  display: block;
}
.left-sidebar nav ul li a i {
  margin-right: 5px;
  font-size: 18px;
}
.left-sidebar nav ul li a .down {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 20px;
  height: 100%;
  display: flex;
  align-items: center;
}
.left-sidebar nav ul li a .down i {
  margin-right: 0;
  font-size: 14px;
  transition: all 0.5s ease-in-out;
}
.left-sidebar nav ul li a .down.active i {
  transform: rotate(90deg);
}
.left-sidebar nav li a.active {
  background: #3e3e3e;
  border-left: 3px solid var(--blue);
  color: var(--white);
}

.left-sidebar nav li a:hover {
  color: var(--white);
}
.left-sidebar nav li a:hover::before {
  width: 100%;
}
.left-sidebar nav li .sub-menu {
  background: #2b2b2b;
  /* display: none; */
  transition: all 0.5s ease-in-out;
  padding: 6px 0;
}
.left-sidebar nav li .sub-menu li a {
  padding: 6px 20px 6px 42px;
}
.left-sidebar nav li .sub-menu li a:hover {
  border-color: #2b2b2b;
}
.left-sidebar.active {
  width: 80px;
  padding: 10px 0;
}
.left-sidebar.active nav ul li a .title-link,
.left-sidebar.active nav ul li a .down {
  display: none;
}
.left-sidebar.active nav ul li a i {
  font-size: 25px;
}
.left-sidebar.active .toggler {
  padding: 15px;
  border-bottom: 1px solid #3e3e3e;
  padding-bottom: 20px;
  align-items: center;
}
.left-sidebar.active nav h5 {
  padding: 0 12px;
  text-align: center;
}
.left-sidebar.active .profile {
  display: none;
}
/*=========================page-wrapper css start=============================*/
.page-wrapper {
  width: calc(100% - 270px);
  margin-left: 270px;
  float: left;
  transition: all 0.5s ease-in-out;
}
.page-wrapper.active {
  width: calc(100% - 80px);
  margin-left: 80px;
}
.page-wrapper .toggler {
  width: 70px;
  height: 70px;
  border-right: 1px solid #dcdcdc;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: none;
}
.page-wrapper .toggler .bar span {
  background: var(--black-color);
}
.page-wrapper header {
  width: 100%;
  float: left;
  background: var(--white);
  position: relative;
}
.page-wrapper .main-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
}
.page-wrapper header .logo {
  width: 70px;
}
.page-wrapper header .account a {
  font-size: 22px;
  margin-left: 8px;
  color: #8f9ca9;
  transition: 0.5s;
}
.page-wrapper header .account a:hover {
  color: var(--blue-color);
}
.page-wrapper .wrapper-body {
  width: 100%;
  float: left;
  padding: 30px;
}
.page-wrapper .wrapper-body .item-sec {
  margin-top: 15px;
  width: 100%;
  float: left;
}
.page-wrapper .wrapper-body .item-sec figure {
  width: 100%;
  float: left;
  background: var(--white);
  padding: 15px;
  margin-top: 30px;
}
.page-wrapper .wrapper-body .item-sec figure .icon {
  width: 45px;
  height: 45px;
  line-height: 45px;
  display: block;
  background: var(--white);
  text-align: center;
  font-size: 24px;
  color: var(--blue-color);
  margin: auto;
  margin-top: -35px;
}
.page-wrapper .wrapper-body .item-sec figure h5 {
  font-size: 17px;
  font-weight: 500;
  text-transform: capitalize;
  text-align: center;
  position: relative;
  margin-bottom: 15px;
  padding-bottom: 15px;
  margin-top: 22px;
  color: var(--heading-color);
}
.page-wrapper .wrapper-body .item-sec figure h5::before {
  position: absolute;
  content: "";
  width: 80%;
  height: 2px;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background: var(--blue-color);
  border-radius: 100%;
}
.page-wrapper .wrapper-body .item-sec figure ul {
  width: 100%;
  float: left;
  margin-top: 5px;
}
.page-wrapper .wrapper-body .item-sec figure ul li a {
  font-size: 14px;
  text-transform: capitalize;
  font-weight: 500;
  color: var(--pera-color);
  position: relative;
  display: block;
  padding: 8px 5px;
  transition: 0.5s ease-in-out;
}
.page-wrapper .wrapper-body .item-sec figure ul li a:hover {
  color: var(--blue-color);
}
.page-wrapper .wrapper-body .item-sec figure ul li a .right {
  position: absolute;
  top: 0;
  right: 0;
  line-height: 37px;
  z-index: 1;
}
/*=========================user management css start=============================*/
.light-blue {
  background: #e6eeff;
}
.blue {
  background: #e6f5ff;
}
.orange {
  background: #fefae6;
}
.purpale {
  background: #ffe6ed;
}
.red {
  background: #fff4f4;
}
.sliver {
  background: #f6f6f6;
}
.light-green {
  background: #f2f5f0;
}
.drk-orange {
  background: #fff7ee;
}
.user-management {
  width: 100%;
  float: left;
}
.user-management .management-info {
  margin-top: 20px;
}
.user-management .management-info figure {
  display: flex;
  padding: 20px;
  background: var(--white);
  width: 100%;
  float: left;
  border-radius: 3px;
  align-items: center;
}
.user-management .management-info figure .icon {
  width: 50px;
  height: 50px;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.user-management .management-info figure .icon img {
  width: 24px;
  height: 28px;
  object-fit: contain;
}
.user-management .management-info figure .detail {
  margin-left: 15px;
  width: calc(100% - 70px);
}
.user-management .management-info figure .detail h6 {
  font-size: 22px;
  color: var(--heading-color);
}
.user-management .management-info figure .detail p {
  font-size: 15px;
  color: var(--gray-color);
  font-weight: 500;
  text-transform: capitalize;
}
.user-management .line-chart-sec {
  background: var(--white);
  padding: 30px;
  margin-top: 10px;
}
.user-management .line-chart-sec h5 {
  font-size: 18px;
  font-weight: 300;
  text-transform: capitalize;
  margin-bottom: 15px;
}
/*=========================eco dashboard css start=============================*/
.eco-chart {
  width: 100%;
  float: left;
  margin-top: 15px;
}
.eco-chart .order-linechart {
  width: 100%;
  float: left;
  margin-top: 20px;
  margin-bottom: 20px;
}
.eco-chart .order-linechart h5,
.bottom-chart h5 {
  position: relative;
  font-size: 18px;
  text-transform: capitalize;
  font-weight: 400;
  color: var(--heading-color);
  margin-bottom: 10px;
}
.eco-chart .order-linechart .chart {
  width: 100%;
  float: left;
  background: var(--white);
  padding: 15px;
}
.bottom-chart {
  margin-top: 20px;
}
.filter-icon {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 20px;
  color: #8f9ca9;
  transition: 0.5s;
}
.filter-icon:hover {
  color: var(--blue);
}
.filter-form {
  display: none;
  max-width: 350px;
  width: 100%;
  padding: 15px;
  position: absolute;
  top: 100%;
  transform: translateY(10px);
  right: 0;
  border-radius: 3px;
  background: var(--white-color);
  z-index: 1;
}
.filter-form form p {
  font-size: 18px;
  font-weight: 500;
  text-transform: lowercase;
  color: #8f9ca9;
  margin-top: 10px;
}
.filter-form form .date {
  position: relative;
  width: 100%;
  float: left;
}
.filter-form form .date .placeholder {
  position: absolute;
  top: 1px;
  left: 1px;
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  background: var(--white-color);
  display: flex;
  align-items: center;
  padding: 0 15px;
  color: #8f9ca9;
  font-size: 22px;
  cursor: pointer;
  border-radius: 0;
}
.filter-form form .date .placeholder small {
  font-size: 14px;
  margin-left: 8px;
  font-weight: 500;
}
/*=========================chat sec css start=============================*/
.heading.line h4 {
  position: relative;
}
.heading.line h4::before {
  position: absolute;
  content: "";
  width: 30px;
  height: 2px;
  bottom: -5px;
  left: 0;
  background: var(--blue-color);
}
.chat-history {
  width: 100%;
  float: left;
  margin-top: 35px;
}
.chat-history .chat-contact {
  background: var(--white-color);
  width: 100%;
  float: left;
}
.chat-history .chat-contact .seach-bar {
  width: 100%;
  float: left;
  padding: 20px;
  line-height: 0;
}
.chat-history .chat-contact .seach-bar .seach-feild {
  position: relative;
  width: 100%;
  float: left;
  border: 1px solid #dcdcdc;
}
.chat-history .chat-contact .seach-bar .seach-feild input {
  width: 100%;
  height: 43px;
  padding: 0 55px 0 10px;
  border: none;
  font-size: 15px;
  font-weight: 500;
  outline: none;
  color: var(--pera-color);
}
.chat-history .chat-contact .seach-bar .seach-feild button {
  position: absolute;
  top: -1px;
  right: -1px;
  border: none;
  width: 44px;
  height: 44px;
  background: var(--blue-color);
  font-size: 20px;
  color: var(--white-color);
}
.chat-history .chat-contact .seach-bar .seach-feild button i {
  transform: rotateY(180deg);
}
.chat-history .chat-contact .contact {
  width: 100%;
  float: left;
}
.chat-history .chat-contact .contact li {
  display: flex;
  align-items: center;
  padding: 17px 20px;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}
.chat-history .chat-contact .contact li:hover {
  background: #f4f7fa;
}
.chat-history .chat-contact .contact li .image,
.chat-history .single-chat .profile-head .image {
  width: 45px;
  height: 45px;
}
.chat-history .chat-contact .contact li .image img,
.chat-history .single-chat .profile-head .image img {
  border-radius: 50%;
}
.chat-history .chat-contact .contact li.active {
  background: #f4f7fa;
}
.chat-history .chat-contact .contact li .detail {
  margin-left: 15px;
  width: calc(100% - 60px);
  position: relative;
}
.chat-history .chat-contact .contact li .detail h6 {
  font-size: 16px;
  text-transform: capitalize;
  color: var(--heading-color);
  width: calc(100% - 22px);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.chat-history .chat-contact .contact li .detail .day {
  position: absolute;
  top: 0;
  right: 10px;
  font-size: 14px;
  font-weight: 500;
  color: #66737f;
}
.chat-history .chat-contact .contact li .detail p {
  font-size: 14px;
  text-transform: capitalize;
  color: #66737f;
  font-weight: 500;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.chat-history .single-chat {
  background: var(--white-color);
}
.chat-history .single-chat .profile-head {
  display: flex;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid #dcdcdc;
}
.chat-history .single-chat .profile-head .name {
  margin-left: 10px;
  text-transform: capitalize;
  font-size: 16px;
  color: var(--heading-color);
}
.chat-history .single-chat .profile-head .name i {
  font-size: 8px;
  color: #00de51;
}
.chat-history .single-chat .profile-chat {
  width: 100%;
  float: left;
  padding: 20px;
}
.chat-history .single-chat .profile-chat .client-chat {
  display: flex;
  margin: 15px 0;
}
.chat-history .single-chat .profile-chat .image {
  width: 45px;
  height: 45px;
}
.chat-history .single-chat .profile-chat .image img {
  border-radius: 50%;
}
.chat-history .single-chat .profile-chat .detail {
  width: calc(100% - 60px);
  margin-left: 12px;
}
.chat-history .single-chat .profile-chat .detail h6 {
  font-size: 16px;
  text-transform: capitalize;
  color: var(--heading-color);
  margin-bottom: 12px;
}
.chat-history .single-chat .profile-chat .detail h6 .time {
  margin-left: 5px;
  text-transform: lowercase;
  font-size: 14px;
  font-weight: 500;
  color: #8f9ca9;
}
.chat-history .single-chat .profile-chat .detail span {
  font-size: 14px;
  font-weight: 500;
  padding: 10px 15px;
  background: #f5f7fa;
  border-radius: 4px;
  color: #777777;
  display: inline-block;
}
.chat-history .single-chat .profile-chat .your-chat {
  display: flex;
  flex-direction: row-reverse;
  margin: 15px 0;
}
.chat-history .single-chat .profile-chat .your-chat .detail {
  text-align: right;
  margin-right: 12px;
  margin-left: 0;
}
.chat-history .single-chat .profile-chat .chat img {
  max-width: 540px;
  width: 100%;
  object-fit: cover;
}
.chat-history .single-chat .profile-chat .img-caption {
  max-width: 540px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  background: #f4f4f4;
  border-radius: 0 0 12px 12px;
}
.chat-history .single-chat .profile-chat .img-caption p {
  font-size: 15px;
  font-weight: 500;
  color: var(--pera-color);
}
.chat-history .single-chat .profile-chat .img-caption span {
  padding: 0;
  background: 0;
}
.chat-history .single-chat .profile-chat .img-caption .icon a {
  font-size: 15px;
  color: var(--gray-color);
  transition: 0.5s ease-in-out;
}
.chat-history .single-chat .profile-chat .img-caption .icon a:hover {
  color: var(--blue-color);
}
.chat-history .single-chat .send-msg {
  width: 100%;
  float: left;
  padding: 20px 0;
}
.chat-history .single-chat .send-msg .input-feild {
  padding: 12px 0;
  border-top: 2px solid #f5f5f5;
  border-bottom: 2px solid #f5f5f5;
  width: 100%;
  float: left;
}
.chat-history .single-chat .send-msg .input-feild textarea {
  width: 100%;
  height: 45px;
  font-size: 16px;
  font-weight: 500;
  outline: none;
  padding: 10px 25px;
  border: none !important;
  resize: none;
}
.chat-history .single-chat .send-msg .input-button {
  width: 100%;
  padding: 12px 25px 0;
  float: left;
  align-items: center;
}
.chat-history .single-chat .send-msg .input-button ul li {
  display: inline-block;
  margin-right: 16px;
}
.chat-history .single-chat .send-msg .input-button ul li a {
  display: block;
  color: #8f9ca9;
  font-size: 18px;
}
.chat-history .single-chat .send-msg .input-button .btn {
  text-align: right;
}
.chat-history .single-chat .send-msg .input-button .btn input {
  width: 100px;
  height: 43px;
  border: none;
  font-weight: 500;
  background: var(--blue-color);
  color: var(--white-color);
  font-size: 16px;
  border-radius: 3px;
}
/*=========================user management css start=============================*/
.user-management-sec .heading .button {
  position: absolute;
  top: 9px;
  right: 0;
}
.user-management-sec .usermgt-details {
  width: 100%;
  float: left;
  margin-top: 35px;
}
.mgt-tabs {
  width: 100%;
  float: left;
  border-bottom: 2px solid #f1f1f1;
  padding: 0 15px;
  padding-bottom: 10px;
  margin-bottom: 15px;
}
.mgt-tabs a {
  position: relative;
  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;
  margin-right: 18px;
  color: #a8b0bf;
}
.mgt-tabs a.active {
  color: var(--heading-color);
}
.mgt-tabs a::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 4px;
  background: var(--blue-color);
  bottom: -14px;
  left: 0;
  transition: 0.5s ease-in-out;
  transform: scale(0);
}
.mgt-tabs a.active::before {
  transform: scale(1);
}
.tabcontent {
  margin-top: 15px;
  width: 100%;
  float: left;
}
.tabcontent .table {
  width: 100%;
  float: left;
  margin-bottom: 0;
}
.tabcontent .table li {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.tabcontent .table li .box:nth-child(1) {
  -ms-flex: 230px;
  flex: 230px;
  max-width: 230px;
}
.tabcontent .table li .box:nth-child(2) {
  -ms-flex: 180px;
  flex: 180px;
  max-width: 180px;
}
.tabcontent .table li .box:nth-child(3) {
  -ms-flex: 130px;
  flex: 130px;
  max-width: 130px;
}
.tabcontent .table li .box:nth-child(4) {
  flex-grow: 2;
}
.tabcontent .table li .box:nth-child(5) {
  -ms-flex: 110px;
  flex: 110px;
  max-width: 110px;
}
.tabcontent .table li .box:nth-child(6) {
  -ms-flex: 90px;
  flex: 90px;
  max-width: 90px;
}
.tabcontent .table li .box:nth-child(7) {
  -ms-flex: 90px;
  flex: 90px;
  max-width: 90px;
}
.tabcontent li.head {
  background: #e9ebee;
}
.tabcontent li.head .box,
.tabcontent li.body .box {
  padding: 7px 12px;
  color: #66737f;
}
.tabcontent li.head .box p,
.tabcontent li.body .box p {
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
}
.tabcontent li.body .box p {
  color: var(--heading-color);
}
.tabcontent li.body {
  background: var(--white-color);
  padding: 14px 0;
}
.tabcontent li.body .box .icon-btn a {
  display: inline-block;
  margin-right: 5px;
}
.tabcontent li.body .box .icon-btn a i {
  font-size: 22px;
}
.tabcontent li.body .box .icon-btn .edit i {
  color: #8f9ca9;
}
.tabcontent li.body .box .icon-btn .delete i {
  color: #ce4a88;
}
.tabcontent li.body {
  margin-bottom: 15px;
}
/*switch toggle btn*/
.switch-btn .switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}
.switch-btn .switch input {
  opacity: 0;
  width: 0;
  height: 0;
  outline: none;
}
.switch-btn .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--white-color);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
  border: 2px solid #8f9ca9;
}
.switch-btn .slider:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 4px;
  top: 50%;
  transform: translateY(-50%);
  background-color: #8f9ca9;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}
.switch-btn input:checked + .slider {
  background-color: var(--white-color);
  border-color: #f44336;
}
.switch-btn input:checked + .slider:before {
  transform: translate(17px, -50%);
  background: #f44336;
}
/*=========================edit user css start=============================*/
.user-management-sec .edit-user {
  width: 100%;
  float: left;
  background: var(--white-color);
}
.user-management-sec .edit-user .heading {
  padding: 25px 35px 30px;
}
.user-management-sec .edit-user .heading h4 {
  margin-top: 0;
}
.user-management-sec .edit-user .user-form {
  width: 100%;
  float: left;
  background: #f9f9f9;
  padding: 10px 35px 35px;
}
.user-management-sec .edit-user .user-form .input-feild {
  width: 100%;
  float: left;
  margin-top: 18px;
}
/*=========================management product css start=============================*/
.product-table {
  width: 100%;
  float: left;
  margin-bottom: 0;
}
.product-table li {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
}
.product-table li .box:nth-child(1) {
  -ms-flex: 130px;
  flex: 130px;
  max-width: 130px;
}
.product-table li .box:nth-child(2) {
  flex-grow: 2;
}
.product-table li .box:nth-child(3) {
  -ms-flex: 110px;
  flex: 110px;
  max-width: 110px;
}
.product-table li .box:nth-child(4) {
  -ms-flex: 150px;
  flex: 150px;
  max-width: 150px;
}
.product-table li .box:nth-child(5) {
  -ms-flex: 130px;
  flex: 130px;
  max-width: 130px;
}
.product-table li .box:nth-child(6) {
  -ms-flex: 150px;
  flex: 150px;
  max-width: 150px;
}
.product-table li .box:nth-child(7) {
  -ms-flex: 100px;
  flex: 100px;
  max-width: 100px;
}
.product-table li.body .box img {
  height: 50px;
  object-fit: contain;
  object-position: left;
}
.product-table li.body .box:nth-child(1) {
  padding: 0 12px;
}
.product-table li.body .box .icon-btn .edit i {
  color: var(--blue-color);
}
.star-ratings {
  width: 100%;
  display: flex;
}
.star-ratings li {
  font-size: 16px;
  color: #c1c9d4;
  display: inherit !important;
  width: auto !important;
  border: 0 !important;
}
.star-ratings li.active i {
  color: #efc100;
}
/*=========================store-table css start=============================*/
.sotre-mgt-sec .button a {
  margin-left: 10px;
}
.sotre-mgt-sec .button {
  display: flex;
}
.sotre-mgt-sec .button form {
  width: 170px;
  border: 1px solid #dcdcdc;
  position: relative;
  border-radius: 2px;
}
.sotre-mgt-sec .button form input {
  width: 100%;
  height: 40px;
  border: none;
  font-size: 14px;
  font-weight: 500;
  padding: 0 10px 0 45px;
  outline: none;
}
.sotre-mgt-sec .button form button {
  position: absolute;
  height: 40px;
  width: 40px;
  border: 0;
  background: var(--white-color);
}
.sotre-mgt-sec .button form button i {
  transform: rotateY(180deg);
}
.sotre-mgt-sec .sotre-mgt .number {
  width: 20px;
  height: 20px;
  line-height: 20px;
  display: inline-block;
  border-radius: 50%;
  background: red;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  color: var(--blue-color);
  background: #e1f2fc;
}
.user-management-sec .usermgt-details .tabcontent .store-table {
  width: 100%;
  float: left;
  margin-bottom: 0;
}
.user-management-sec .usermgt-details .tabcontent .store-table li {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.user-management-sec
  .usermgt-details
  .tabcontent
  .store-table
  li
  .box:nth-child(1) {
  -ms-flex: 210px;
  flex: 210px;
  max-width: 210px;
}
.user-management-sec
  .usermgt-details
  .tabcontent
  .store-table
  li
  .box:nth-child(2) {
  -ms-flex: 150px;
  flex: 150px;
  max-width: 150px;
}
.user-management-sec
  .usermgt-details
  .tabcontent
  .store-table
  li
  .box:nth-child(3) {
  -ms-flex: 170px;
  flex: 170px;
  max-width: 170px;
}
.user-management-sec
  .usermgt-details
  .tabcontent
  .store-table
  li
  .box:nth-child(4) {
  -ms-flex: 120px;
  flex: 120px;
  max-width: 120px;
}
.user-management-sec
  .usermgt-details
  .tabcontent
  .store-table
  li
  .box:nth-child(5) {
  flex-grow: 2;
}
.user-management-sec
  .usermgt-details
  .tabcontent
  .store-table
  li
  .box:nth-child(6) {
  -ms-flex: 90px;
  flex: 90px;
  max-width: 90px;
}
.user-management-sec
  .usermgt-details
  .tabcontent
  .store-table
  li
  .box:nth-child(7) {
  -ms-flex: 90px;
  flex: 90px;
  max-width: 90px;
}
.user-management-sec
  .usermgt-details
  .tabcontent
  .store-table
  li
  .box
  .fa-sort {
  font-size: 13px;
  margin-left: 3px;
}
/*=========================sore-details css start=============================*/
.checkbox {
  margin-top: 12px;
}
.checkbox input {
  height: 0;
  position: absolute;
  width: 0;
}
.checkbox-input {
  margin-left: 10px;
  margin-bottom: 10px;
}
.sotre-details-sec .edit-user .user-form {
  background: var(--white-color);
}
.sotre-details-sec .mgt-tabs {
  margin-bottom: 0;
}
.sotre-details-sec .sotre-tabs {
  padding: 0 35px;
}
.store-product-sec {
  padding: 0 35px;
  margin-top: 20px;
}
.store-product-table {
  width: 100%;
  float: left;
  margin-bottom: 0;
}
.store-product-table li {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
}
.store-product-table li .box:nth-child(1) {
  -ms-flex: 100px;
  flex: 100px;
  max-width: 100px;
}
.store-product-table li .box:nth-child(2) {
  flex-grow: 2;
}
.store-product-table li .box:nth-child(3) {
  -ms-flex: 90px;
  flex: 90px;
  max-width: 90px;
}
.store-product-table li .box:nth-child(4) {
  -ms-flex: 130px;
  flex: 130px;
  max-width: 130px;
}
.store-product-table li .box:nth-child(5) {
  -ms-flex: 130px;
  flex: 130px;
  max-width: 130px;
}
.store-product-table li .box:nth-child(6) {
  -ms-flex: 150px;
  flex: 150px;
  max-width: 150px;
}
.store-product-table li .box:nth-child(7) {
  -ms-flex: 100px;
  flex: 100px;
  max-width: 100px;
}
.store-product-table li.body .box img {
  height: 50px;
  object-fit: contain;
  object-position: left;
}
.store-product-table li.body .box:nth-child(1) {
  padding: 0 12px;
}
.store-product-table li.body .box .icon-btn .edit i {
  color: var(--blue-color);
}
/*=========================eidt product css start=============================*/
.product-details-sec {
  width: 100%;
  padding: 0 20px 60px;
  float: left;
}
.product-details-sec .left-panel ul {
  width: 100%;
  float: left;
  height: 350px;
}
.product-details-sec .left-panel ul li {
  width: 100%;
  float: left;
}
.product-details-sec .left-panel ul li a {
  display: block;
  padding: 10px 20px;
  color: #8f9ca9;
  font-size: 14px;
  font-weight: 500;
  border-left: 2px solid transparent;
  position: relative;
  transition: all 0.5s ease-in-out;
  z-index: 1;
}
.product-details-sec .left-panel ul li a i {
  margin-right: 3px;
  font-size: 20px;
}
.product-details-sec .left-panel ul li.active a {
  background: #f4fbff;
  border-color: var(--blue-color);
  color: var(--blue-color);
}
.product-details-sec .left-panel ul li a::before {
  position: absolute;
  content: "";
  width: 0;
  height: 100%;
  background: #f4fbff;
  top: 0;
  left: 0;
  z-index: -1;
  transition: 0.5s ease-in-out;
  transition-delay: 0.2s;
}
.product-details-sec .left-panel ul li a:hover {
  color: var(--blue-color);
  border-color: var(--blue);
}
.product-details-sec .left-panel ul li a:hover::before {
  width: 100%;
}
.product-details-sec .right-panel {
  padding: 15px 20px;
}
.product-details-sec .right-panel h5 {
  font-size: 18px;
  font-weight: 400;
  color: var(--heading-color);
  text-transform: capitalize;
  margin-bottom: 15px;
}
.right-panel .form .input-feild {
  margin-bottom: 15px;
}
.right-panel .form .input-feild textarea {
  height: 120px;
  padding: 10px;
}
.right-panel .form .custom-select {
  font-size: 15px;
  padding: 0 10px;
}
.right-panel .form .name {
  font-size: 15px;
  font-weight: 500;
  color: var(--heading-color);
  text-transform: capitalize;
}
.right-panel .checkbg {
  background: #f8f8f8;
  padding: 15px 20px;
}
.right-panel .form .button {
  padding-bottom: 15px;
  margin-top: 20px;
}
.right-panel .form .button input {
  width: 130px;
  height: 40px;
  border: none;
  background: #e9ebee;
  font-size: 15px;
  font-weight: 500;
  color: #66737f;
  border-radius: 3px;
}
.right-panel .form .button .next {
  float: right;
  background: var(--blue-color);
  color: var(--white-color);
}
.right-panel .check-form {
  padding: 15px;
  background: #f7f7f7;
  margin-top: 10px;
  position: relative;
  z-index: 1;
  display: none;
  width: 100%;
  float: left;
}
.right-panel .check-form::before {
  position: absolute;
  content: "";
  top: -7px;
  left: 4px;
  background: #f7f7f7;
  width: 14px;
  height: 14px;
  transform: rotate(45deg);
  z-index: -1;
}
.right-panel .check-form .checkbox-input input {
  display: none;
}
.right-panel .check-form .check-active {
  display: flex;
  width: 100%;
  margin-left: 20px;
}
.right-panel .check-form .name {
  font-size: 14px;
}
.right-panel .check-form .check-active .checkbox-input {
  -ms-flex: 50px;
  flex: 50px;
  max-width: 50px;
}
.right-panel .tag-sec {
  width: 100%;
  height: 120px;
  float: left;
  padding: 15px;
  padding-top: 0;
  border: 1px solid #dcdcdc;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 4px;
}
.tag-container {
  border-radius: 3px;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
}
.tag-container .tag {
  height: 30px;
  margin-right: 8px;
  padding: 5px 6px;
  border-radius: 3px;
  background: #f5dbe7;
  display: flex;
  align-items: center;
  color: #ce4a88;
  font-size: 15px;
  font-weight: 500;
  cursor: default;
  margin-top: 10px;
}
.tag i {
  font-size: 14px;
  color: #ce4a88;
  margin-left: 5px;
  cursor: pointer;
}
.tag-container input {
  padding: 5px;
  font-size: 15px;
  border: 0;
  box-shadow: 0;
  height: 30px;
  box-shadow: none;
  outline: none;
  color: var(--heading-color);
  flex: 1;
  font-weight: 500;
  margin-top: 10px;
}
/*=========================edit-product-details sec css start=============================*/
.edit-product-details form .custom-select {
  font-weight: 500;
  height: 43px;
}
.edit-product-details .size-input {
  padding: 10px 15px;
}
.edit-product-details .size-input h5,
.edit-product-details .size-input .dimensions-input h6 {
  font-size: 16px;
  font-weight: 400;
  color: var(--heading-color);
  margin-bottom: 10px;
  text-transform: capitalize;
}
.edit-product-details .size-input .dimensions-input {
  width: 100%;
  float: left;
  padding: 20px;
  background: #f9f9f9;
}
.edit-product-details .size-input .dimensions-input:nth-child(2) {
  padding-bottom: 0;
}
.edit-product-details .size-input .dimensions-input .input-inner {
  width: 100%;
  display: flex;
  align-items: center;
}
.edit-product-details .size-input .dimensions-input .same-size {
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  max-width: 20%;
  height: 43px;
}
.edit-product-details .size-input .dimensions-input .icon {
  width: 50px;
  text-align: center;
  font-size: 18px;
  text-transform: capitalize;
}
.edit-product-details .size-input .dimensions-input input {
  padding: 0 12px;
  color: #272d37;
  font-size: 14px;
  font-weight: 500;
  outline: none;
  border-radius: 0;
  background: var(--white);
  border: none;
  border: 1px solid #dcdcdc;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 4px;
}
.edit-product-details .new-feild {
  display: inline-block;
  color: var(--blue-color);
  font-size: 16px;
  text-transform: capitalize;
  margin-top: 10px;
  margin-left: 15px;
  font-weight: 500;
}
.edit-product-details .new-feild:hover {
  color: var(--blue-color);
}
.edit-product-details .new-feild i {
  font-size: 20px;
  position: relative;
  top: 2px;
}
.edit-product-details .new-feildsec {
  padding: 10px 15px;
  display: none;
}
.edit-product-details .new-feildsec h6 {
  margin-bottom: 10px;
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 400;
}
.edit-product-details .attach-files {
  width: 100%;
  padding: 15px;
  border: 1px dashed #dcdcdc;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.edit-product-details .attach-files .add-image {
  width: 55px;
  height: 55px;
  line-height: 55px;
  border: 1px dashed #dcdcdc;
  cursor: pointer;
  text-align: center;
}
.edit-product-details .attach-files .add-image label {
  margin-bottom: 0;
  width: 100%;
  cursor: pointer;
}
.edit-product-details .attach-files .add-image i {
  cursor: pointer;
  font-size: 20px;
  color: #8f9ca9;
}
.edit-product-details .attach-files .add-image input {
  display: none;
}
.edit-product-details .attach-files li {
  display: inline-block;
  font-size: 15px;
  background: #f0efef;
  border-radius: 2px;
  margin-right: 12px;
  position: relative;
  margin-top: 10px;
}
.edit-product-details .attach-files li img {
  width: 60px;
  height: 60px;
  background: #f8f8f8;
  padding: 10px;
}
.edit-product-details .attach-files li .closefile {
  position: absolute;
  top: 1px;
  right: 1px;
  line-height: 0;
  text-align: center;
  border-radius: 2px;
}
.edit-product-details .attach-files li i {
  font-size: 10px;
  cursor: pointer;
  color: #ce4a88;
}
.edit-product-details .attach-files .add-image.active {
  margin-top: 7px;
  margin-left: 5px;
}
.edit-product-details .new-feildsec .radio {
  margin: 0;
  margin-right: 10px;
}
.right-panel-bottom,
.right-panel-top {
  width: 100%;
  float: left;
  margin-top: 25px;
  padding: 20px;
  display: none;
}
.right-panel-bottom .head,
.right-panel-top .head {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}
.right-panel-bottom h4,
.right-panel-top h4 {
  font-size: 18px;
  font-weight: 400;
  text-transform: capitalize;
  position: relative;
}
.right-panel-bottom .button a,
.right-panel-top .button a {
  width: 130px;
  height: 35px;
  line-height: inherit;
  font-size: 13px;
}
.right-panel-bottom .button a i {
  font-size: 17px;
}
.right-panel-top {
  display: block;
  margin-top: 0;
  margin-bottom: 20px;
}
/*=========================pending varification css start=============================*/
.icon-btn .green i {
  color: #009d50 !important;
}
.icon-btn .red i {
  color: #d80027 !important;
}
.icon-btn .red {
  background: transparent;
}
/*=========================view pending varification css start=============================*/
.view-pendingvar .edit-user .heading {
  padding-bottom: 0;
}
.view-pendingvar .edit-user .user-form {
  background: var(--white-color);
}
.view-pendingvar .input-button input {
  width: 170px;
  height: 38px;
  font-size: 14px;
}
.view-pendingvar .input-button button {
  width: 125px;
  height: 38px;
  font-size: 14px;
  background: #009d50;
  color: var(--white-color);
  margin-left: 15px;
  text-transform: capitalize;
}
.view-pendingvar .input-button button i {
  font-size: 18px;
  position: relative;
  top: 2px;
}
.view-pendingvar .input-button button.red {
  background: #d80027;
}
/*=========================ecommerce product category css start=============================*/
.eco-product-category .button a {
  width: 210px;
}
.eco-product-category .small-size a {
  width: 150px;
}
.eco-product-category .product-category-table li .box:nth-child(1) {
  -ms-flex: 210px;
  flex: 210px;
  max-width: 210px;
}
.eco-product-category .product-category-table li .box:nth-child(2) {
  flex-grow: 6;
  max-width: inherit;
}
.eco-product-category .product-category-table li .box:nth-child(3) {
  -ms-flex: 90px;
  flex: 90px;
  max-width: 90px;
}
.eco-addcategory .custom-select {
  height: 43px;
}
.eco-addcategory .input-button input {
  width: 210px;
}
/*=========================ecommerce all product css start=============================*/
.user-management-sec .eco-all-product {
  background: transparent;
}
.user-management-sec .eco-all-product .heading {
  padding: 25px 0 30px;
}
.user-management-sec .eco-all-product i {
  font-size: 8px;
}
.user-management-sec .eco-all-product .featured-icon {
  color: #94f6af;
}
.user-management-sec .eco-all-product .hot-icon {
  color: #9c94f6;
}
.user-management-sec .eco-all-product .special-icon {
  color: #ff6d6d;
}
.eco-all-product .store-product-sec {
  padding: 0;
}
.eco-product-table {
  width: 100%;
  float: left;
  margin-bottom: 0;
}
.eco-product-table li.body .box {
  padding: 0 12px !important;
}
.eco-product-table li.body .image {
  display: flex;
  align-items: center;
}
.eco-product-table li.head .box i {
  font-size: 14px;
}
.eco-product-table li {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
}
.eco-product-table li .box:nth-child(1) {
  -ms-flex: 120px;
  flex: 120px;
  max-width: 120px;
}
.eco-product-table li .box:nth-child(2) {
  -ms-flex: 310px;
  flex: 310px;
  max-width: 310px;
}
.eco-product-table li .box:nth-child(3) {
  -ms-flex: 100px;
  flex: 100px;
  max-width: 100px;
}
.eco-product-table li .box:nth-child(4) {
  -ms-flex: 120px;
  flex: 120px;
  max-width: 120px;
}
.eco-product-table li .box:nth-child(5) {
  -ms-flex: 100px;
  flex: 100px;
  max-width: 100px;
}
.eco-product-table li .box:nth-child(6) {
  -ms-flex: 120px;
  flex: 120px;
  max-width: 120px;
}
.eco-product-table li .box:nth-child(7) {
  -ms-flex: 110px;
  flex: 110px;
  max-width: 110px;
}
.eco-product-table li .box:nth-child(8) {
  -ms-flex: 40px;
  flex: 40px;
  max-width: 40px;
}
.eco-product-table li.body .box img {
  height: 50px;
  object-fit: contain;
  object-position: left;
}
.eco-product-table li.body .box .icon-btn .edit i {
  color: var(--blue-color);
}
.dashboard-wrapper{display: -webkit-box;display: -ms-flexbox;display: flex;}
.dashboard-wrapper .left-sidebar {display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-orient: vertical;-webkit-box-direction: normal;-ms-flex-direction: column;flex-direction: column;padding-left: 0;margin-bottom: 0;list-style: none;}
.dashboard-wrapper .left-sidebar{padding: 0;height: auto;min-height: 100vh;position: static;overflow: visible;}
.dashboard-wrapper .page-wrapper{margin-left: 0;float: none;}
.left-sidebar .toggler-wraper{padding: 15px 15px 20px;display: -webkit-box;display: -ms-flexbox;display: flex;justify-content: space-between;}
.left-sidebar .toggler-wraper .danzflo-logo img {max-height: 50px;}
/*========= hamburger menu css ========*/
.burger-dshBtn{background-color: transparent;box-shadow: none;border: none;width: 2.5rem;height: 2.5rem;}
.burger-dshBtn:hover, 
.burger-dshBtn:focus{border: none;outline: none;}
.burger-dshBtn .dshBtn_toggle{width: 40px;}
.dshBtn_toggle {width: 40px;height: 40px;position: relative;cursor: pointer;display: block;}
.dshBtn_toggle .hamburger span {background: #ffffff;}
.dshBtn_toggle .hamburger-cross span {background: #ffffff;}
.dshBtn_toggle .hamburger {position: absolute;height: 100%;width: 100%;display: block;}
.dshBtn_toggle .hamburger span {width: 0%;height: 2px;position: relative;top: 0;left: 0;margin: 8px 0;margin-left: auto; display: block;border-radius: 3px;-webkit-transition: .2s ease-in-out;-o-transition: .2s ease-in-out;transition: .2s ease-in-out;}
.dshBtn_toggle .hamburger span:nth-child(1) {-webkit-transition-delay: 0s;-o-transition-delay: 0s;transition-delay: 0s;}
.dshBtn_toggle .hamburger span:nth-child(2) {-webkit-transition-delay: .125s;-o-transition-delay: .125s;transition-delay: .125s;}
.dshBtn_toggle .hamburger span:nth-child(3) {-webkit-transition-delay: .2s;-o-transition-delay: .2s;transition-delay: .2s;}
.dshBtn_toggle .hamburger-cross {position: absolute;height: 100%;width: 100%;-webkit-transform: rotate(45deg);-ms-transform: rotate(45deg);transform: rotate(45deg);display: block;}
.dshBtn_toggle .hamburger-cross span {display: block;border-radius: 3px;-webkit-transition: .2s ease-in-out;-o-transition: .2s ease-in-out;transition: .2s ease-in-out;}
.dshBtn_toggle .hamburger-cross span:nth-child(1) {height: 100%;width: 2px;position: absolute;top: 0;left: 18px;-webkit-transition-delay: .3s;-o-transition-delay: .3s;transition-delay: .3s;}
.dshBtn_toggle .hamburger-cross span:nth-child(2) {width: 100%;height: 2px;position: absolute;left: 0;top: 18px;-webkit-transition-delay: .4s;  -o-transition-delay: .4s;transition-delay: .4s;}
.left-sidebar:not(.active) .burger-dshBtn .dshBtn_toggle .hamburger span:nth-child(1){width: 35px;}
.left-sidebar:not(.active) .burger-dshBtn .dshBtn_toggle .hamburger span:nth-child(3){width: 30px;height: 2px;}
.left-sidebar:not(.active) .burger-dshBtn .dshBtn_toggle .hamburger span {width: 100%;}
.left-sidebar:not(.active) .burger-dshBtn .dshBtn_toggle .hamburger span:nth-child(1) {-webkit-transition-delay: .3s;-o-transition-delay: .3s;transition-delay: .3s;}
.left-sidebar:not(.active) .burger-dshBtn .dshBtn_toggle .hamburger span:nth-child(2) {-webkit-transition-delay: .4s;-o-transition-delay: .4s;transition-delay: .4s;}
.left-sidebar:not(.active) .burger-dshBtn .dshBtn_toggle .hamburger span:nth-child(3) {-webkit-transition-delay: .5s;-o-transition-delay: .5s;transition-delay: .5s;}
.left-sidebar:not(.active) .burger-dshBtn .dshBtn_toggle .hamburger-cross span:nth-child(1) {height: 0%;-webkit-transition-delay: 0s;-o-transition-delay: 0s;transition-delay: 0s;}
.left-sidebar:not(.active) .burger-dshBtn .dshBtn_toggle .hamburger-cross span:nth-child(2) {width: 0%;-webkit-transition-delay: .2s;-o-transition-delay: .2s;transition-delay: .2s;}
/*==================  hamburger menu css ===============*/
.left-sidebar.active .danzflo-logo{display: none;}
.left-sidebar.active .toggler-wraper {justify-content: flex-end;}
.page-wrapper .main-header{justify-content: flex-end;}
.main-header .navbar-nav {-webkit-box-orient: horizontal;-webkit-box-direction: normal;-ms-flex-direction: row;flex-direction: row;}
.main-header .navbar-nav .nav-item{margin-right: 0;}
.main-header .navbar-nav .nav-item .nav-link{height: 3.125rem;display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;padding: 0 0.75rem;}
.main-header .navbar-nav .nav-item .nav-link{color: #d1d3e2;}
.main-header .navbar-nav .nav-item .nav-link span{font-size: 80%;font-weight: 400;margin-right: 0.5rem;color: #858796;}
.main-header .navbar-nav .nav-item .nav-link img{border-radius: 50%;height: 2rem;width: 2rem;}
.main-header .navbar-nav .nav-item .nav-link{background-color: transparent;border: none;font-size: 18px;}
.main-header .navbar-nav .topbar-divider {width: 0;border-right: 1px solid #e3e6f0;height: calc(4.375rem - 2rem);margin: auto 1rem;}
.left-sidebar .dsh-card-wraper .card-user-wraper{position: relative;display: -webkit-box;display: -moz-box;display: -ms-flexbox;display: -webkit-flex; display: flex;-webkit-justify-content: center;-moz-justify-content: center;-ms-justify-content: center; justify-content: center;-webkit-flex-wrap: wrap;-moz-flex-wrap: wrap;-ms-flex-wrap: wrap; flex-wrap: wrap; }
.left-sidebar .dsh-card-wraper .card-user{position: relative;width: 100%;}
.left-sidebar .dsh-card-wraper .card-user .user-img-wraper{background: rgba(0, 166, 206, 0.4);height: 115px;width: 115px;-webkit-border-radius: 100%;-moz-border-radius: 100%;border-radius: 100%;overflow: hidden;margin: auto;text-align: center;display: -webkit-box;display: -moz-box;display: -ms-flexbox;display: -webkit-flex; display: flex;-webkit-justify-content: center;-moz-justify-content: center;-ms-justify-content: center; justify-content: center;-webkit-align-items: center;-moz-align-items: center;-ms-align-items: center; align-items: center;transition: all .3s linear;}
.left-sidebar.active .dsh-card-wraper .card-user .user-img-wraper{height: 60px;width: 60px;}
.left-sidebar .dsh-card-wraper .card-user .user-img-wraper .user-img{height: 100px;width: 100px;overflow: hidden;-webkit-border-radius: 100%;-moz-border-radius: 100%; border-radius: 100%;transition: all .3s linear;}
.left-sidebar.active .card-user .user-img-wraper .user-img{height: 45px;width: 45px;}
.left-sidebar .dsh-card-wraper .card-user .user-img-wraper .user-img img{height: 100%;}
.left-sidebar .dsh-card-wraper .card-user .edit-user-img{position: absolute;bottom: 6px;right: -6px;}
.left-sidebar .dsh-card-wraper .card-user .edit-user-img a{height: 30px;line-height: 30px;width: 30px;display: block;text-align: center;background: #FFFFFF;-webkit-box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.06);-moz-box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.06);box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.06);-webkit-border-radius: 100%;-moz-border-radius: 100%;border-radius: 100%;font-size: 18px;color: #2AA8F2;}
.left-sidebar .dsh-card-wraper .card-user-wraper .user-heading{width: 100%;text-align: center;margin-top: 15px;}
.left-sidebar.active .dsh-card-wraper .card-user-wraper .user-heading{display: none;}
.left-sidebar .dsh-card-wraper .card-user-wraper .user-heading h4{font-weight: 400;margin-bottom: 0;line-height: 24px;text-transform: capitalize;color: #FFFFFF;font-size: 15px;}
.left-sidebar .dsh-card-wraper .card-user-wraper .user-heading p{color: #FFFFFF;}