@media screen and (max-width: 1200px) {
	/*header css*/
	.page-wrapper .toggler,.close-toggler{display:flex;}
	.page-wrapper .main-header{margin-left:70px;}
	.left-sidebar{left:-100%;z-index:22;padding-top:10px;}
	.left-sidebar.show{left:0;}
	.page-wrapper{width:100%;margin-left:0;}
	.toggler{display:none;}
}
@media screen and (max-width: 992px) {
	/*all bottom css start*/
	.user-management-sec .usermgt-details .tabcontent li.head,.store-product-table li.head,.eco-product-table li.head{display:none;}	
	.user-management-sec .usermgt-details .tabcontent li.body .box,.store-product-table li.body .box{-ms-flex:50%!important;flex:50%!important;max-width:50%!important;position:relative;padding-top: 15px;padding-bottom: 20px;align-self: center;}
	.user-management-sec .usermgt-details .tabcontent li .box::before,.store-product-table li .box::before{position:absolute;content:attr(data-tigger);width:100%;left:0;top:-14px;padding: 4px 12px;font-size: 14px;font-weight: 500;}
	.user-management-sec .usermgt-details .tabcontent li,.store-product-table li{border-top:3px solid var(--blue-color);}
	.user-management-sec .usermgt-details .tabcontent li:nth-child(odd),.store-product-table li:nth-child(odd){border-top:3px solid #CE4A88;}
	.user-management-sec .usermgt-details .tabcontent .product-table li.body .box:nth-child(1),.store-product-table li.body .box:nth-child(1){padding:20px 12px;}
	.store-product-table li.body .box{-ms-flex:50%!important;flex:100%!important;max-width:100%!important;}
}
@media screen and (max-width: 768px) {
	/*all bottom css*/
	.user-management .line-chart-sec{padding:20px;}
	.user-management-sec .usermgt-details .tabcontent li.body .box{-ms-flex:100%!important;flex:100%!important;max-width:100%!important;padding-top:12px;}
	.switch-btn .switch,.icon-btn{margin-top:5px;float:left;}
	.user-management-sec .usermgt-details .tabcontent li .box:last-child{padding-bottom:0;}
	.user-management-sec .usermgt-details .tabcontent li.body{margin-bottom:25px;padding-top: 20px;}
	.sotre-mgt-sec .button{position:inherit;margin-top:15px;}
	.sotre-mgt-sec .button a{width:160px!important;}
	.product-details-sec .left-panel ul{height:auto;}
	.product-details-sec .right-panel h5{margin-bottom:0;padding-bottom:15px;border-bottom: 1px solid #dcdcdc;}
	.product-details-sec .right-panel{box-shadow:none;padding:15px 0;}
	.tag-container .tag,.tag-container input{margin-top:8px;}
	.edit-product-details .size-input h5, .edit-product-details .size-input .dimensions-input h6{border-bottom:none;}
	.user-management-sec .heading .button{top:-5px;}
}
@media screen and (max-width: 576px) {
	/*all bottom css	*/
	.user-management .management-info .row .col-12:last-child figure,.eco-chart .order-linechart{margin-bottom:0;}
	.bottom-chart{margin-top:20px;}
	.chat-history .single-chat .send-msg .input-button ul li:last-child{margin-right:0;}
	.user-management-sec .usermgt-details .mgt-tabs{padding:0 0 10px;}
	.user-management-sec .button a{width:140px;}
	.right-panel .form .button input{width:180px;}
	.right-panel .form .button .next{margin-top:15px;float:left;}
	.edit-product-details .size-input .dimensions-input .input-inner{flex-wrap: wrap;}
	.edit-product-details .size-input .dimensions-input .same-size{-ms-flex: 0 0 35%;flex: 0 0 35%;max-width: 35%;margin-bottom:15px;}
	.edit-product-details .size-input .dimensions-input .icon {width: 30px; font-size: 15px;margin-bottom:10px;}
	.right-panel-bottom .head{flex-direction:column;align-items:flex-start;}
	.right-panel-bottom .button{margin-top:10px;}
	.edit-product-details .new-feildsec h6{margin-top:15px;}
	.edit-product-details .size-input .dimensions-input:last-child{padding-top:5px;}
	.user-management-sec .heading .button{position:inherit;top:15px;}
	.view-pendingvar .input-button input{width:100%;}
	.view-pendingvar .input-button button{margin-left:0;margin-top:10px;width:calc(100% - 50% - 8px);}
	.view-pendingvar .input-button button:last-child{margin-left:10px;}
	.eco-product-category .usermgt-details{margin-top:0;}
	.eco-product-category .heading .small-size{margin:15px 0 10px;position:initial;display:block;}
}